import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Button,
	Card,
	Col,
	Collapse,
	Form,
	message,
	notification,
	Row,
	Upload,
} from "antd";
import FloatInput from "../../../providers/FloatInput";
import FloatInputMask from "../../../providers/FloatInputMask";
import FloatSelect from "../../../providers/FloatSelect";
import FloatTextArea from "../../../providers/FloatTextArea";
import checkInternetConnection from "../../../providers/checkInternetConnection";
import { GET, POSTFILE } from "../../../providers/useAxiosQuery";
import setStorage from "../../../providers/setStorage";
import getStorage from "../../../providers/getStorage";
import localForage from "localforage";

import {
	formats,
	modulesToolBarV2,
} from "../../../providers/reactQuillOptions";

import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import { InboxOutlined } from "@ant-design/icons";
import getBase64Image from "../../../providers/getBase64Image";
Quill.register("modules/imageResize", ImageResize);

// const dataURLtoBlob = (dataurl) => {
// 	var arr = dataurl.split(","),
// 		mime = arr[0].match(/:(.*?);/)[1],
// 		bstr = atob(arr[1]),
// 		n = bstr.length,
// 		u8arr = new Uint8Array(n);
// 	while (n--) {
// 		u8arr[n] = bstr.charCodeAt(n);
// 	}
// 	return new Blob([u8arr], { type: mime });
// };

export default function PageComplaintForm(props) {
	const { location } = props;
	let id = location.state ? location.state : "";
	const history = useHistory();

	console.log("id", id);
	console.log("location", location);

	const [form] = Form.useForm();
	const [barangays, setBarangays] = useState([]);

	if (id) {
		console.log("edit", id);
	}

	useEffect(() => {
		getStorage("barangays").then((res) => {
			if (res) {
				setBarangays(JSON.parse(res));
			}
		});
	}, []);

	if (checkInternetConnection()) {
		/** for barangay data */
		GET("api/barangay", "barangay_complain_select", (res) => {
			// console.log("res", res);
			if (res.data) {
				setBarangays(
					res.data.barangays.map((item) => {
						return {
							label: item.barangay,
							value: item.id,
						};
					})
				);
				setStorage(
					"barangays",
					JSON.stringify(
						res.data.barangays.map((item) => {
							return {
								label: item.barangay,
								value: item.id,
							};
						})
					)
				);
			}
		});
	}

	/** for create */
	const { mutate: mutateCreateComplaint, isLoading: isLoadingCreateComplaint } =
		POSTFILE("api/complain", "complaintssssssss_create");

	const onFinish = (values) => {
		console.log("onFinish", values);

		if (checkInternetConnection()) {
			const newFormData = new FormData();
			newFormData.append(
				"complainant_name",
				values.complainant_name ? values.complainant_name : ""
			);
			newFormData.append("complaine", values.complaine ? values.complaine : "");
			newFormData.append(
				"description",
				values.description ? values.description : ""
			);
			newFormData.append(
				"contact_no",
				values.contact_no ? values.contact_no : ""
			);
			newFormData.append(
				"email_address",
				values.email_address ? values.email_address : ""
			);
			newFormData.append(
				"barangay_id",
				values.barangay_id ? values.barangay_id : ""
			);
			newFormData.append("address", values.address ? values.address : "");
			newFormData.append("countFiles", values.attachments.length);
			if (values.attachments.length !== 0) {
				for (let i = 0; i < values.attachments.length; i++) {
					const el = values.attachments[i];
					let pictureAtt = el.originFileObj;
					newFormData.append("files_" + i, pictureAtt, el.name);
				}
			}
			mutateCreateComplaint(newFormData, {
				onSuccess: (res) => {
					if (res.success) {
						notification.success({
							message: "Complaint",
							description: "Data submitted successfully.",
						});
						localForage.removeItem("complaintData");
						history.push("/complaint");
					}
				},
				onError: (err) => {
					notification.error({
						message: "Complaint",
						description: err.response.data.message,
					});
				},
			});
		} else {
			let attachments = [];
			if (values.attachments.length > 0) {
				for (let x = 0; x < values.attachments.length; x++) {
					const el = values.attachments[x];
					getBase64Image(el.originFileObj, (fileUrl) =>
						attachments.push({
							name: el.name,
							fileUrl,
						})
					);
				}
			}

			// console.log("attachments", attachments);

			let dataStorage = { ...values, attachments };

			getStorage("complaintData").then((res) => {
				console.log("res", res);
				if (res) {
					let extractRes = JSON.parse(res);
					// console.log("extractRes", extractRes);
					extractRes.push(dataStorage);
					setStorage("complaintData", JSON.stringify(extractRes));
				} else {
					setStorage("complaintData", JSON.stringify([dataStorage]));
				}
			});
			// localForage.setItem("complaintData", JSON.stringify(dataStorage));
			notification.error({
				message: "Complaint",
				description:
					"You are in offline mode. Check internet connection. Data save in device.",
			});
		}
	};

	return (
		<Card>
			<Form form={form} onFinish={onFinish} initialValues={{ description: "" }}>
				<Row gutter={[12, 12]}>
					<Col xs={24} sm={24} md={24} lg={24} xl={16}>
						<Collapse
							className="main-1-collapse border-none"
							expandIcon={({ isActive }) =>
								isActive ? (
									<span
										className="ant-menu-submenu-arrow"
										style={{ color: "#FFF", transform: "rotate(270deg)" }}
									></span>
								) : (
									<span
										className="ant-menu-submenu-arrow"
										style={{ color: "#FFF", transform: "rotate(90deg)" }}
									></span>
								)
							}
							defaultActiveKey={["1"]}
							expandIconPosition="start"
						>
							<Collapse.Panel
								header="ATTACHMENTS"
								key="1"
								className="accordion bg-darkgray-form m-b-md border bgcolor-1 white"
							>
								<Row gutter={[12, 12]}>
									<Col xs={24} sm={24} md={24}>
										<Form.Item
											name="attachments"
											valuePropName="fileList"
											getValueFromEvent={(e) => {
												if (Array.isArray(e)) {
													return e;
												}

												return e?.fileList;
											}}
										>
											<Upload.Dragger
												// className="upload-w-100 upload-hide-remove-icon"
												accept="video/mp4, image/jpeg, image/png, image/jpg"
												multiple={true}
												beforeUpload={(file) => {
													let error = false;
													const isLt2M = file.size / 102400 / 102400 < 10;
													if (!isLt2M) {
														message.error(
															"Image / Video must smaller than 10MB!"
														);
														error = Upload.LIST_IGNORE;
													}
													return error;
												}}
											>
												<p className="ant-upload-drag-icon">
													<InboxOutlined />
												</p>

												<p className="ant-upload-text">
													Click or drag file to this area to upload
												</p>
												<p className="ant-upload-hint">
													Support for a single or bulk upload. Can accept
													([Video/MP4] / [Image / png, jpg, jpeg])
												</p>
											</Upload.Dragger>
										</Form.Item>
									</Col>
								</Row>
							</Collapse.Panel>
						</Collapse>

						<Collapse
							className="main-1-collapse border-none"
							expandIcon={({ isActive }) =>
								isActive ? (
									<span
										className="ant-menu-submenu-arrow"
										style={{ color: "#FFF", transform: "rotate(270deg)" }}
									></span>
								) : (
									<span
										className="ant-menu-submenu-arrow"
										style={{ color: "#FFF", transform: "rotate(90deg)" }}
									></span>
								)
							}
							defaultActiveKey={["1"]}
							expandIconPosition="start"
						>
							<Collapse.Panel
								header="Complaint Information"
								key="1"
								className="accordion bg-darkgray-form m-b-md border bgcolor-1 white"
							>
								<Row gutter={[12, 12]}>
									<Col xs={24} sm={24} md={24} lg={12}>
										<Form.Item
											name="complainant_name"
											rules={[
												{
													required: true,
													message: "This field is required",
												},
											]}
										>
											<FloatInput
												label="Complainant Name"
												placeholder="Complainant Name"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} sm={24} md={24} lg={12}>
										<Form.Item
											name="complaine"
											rules={[
												{
													required: true,
													message: "This field is required",
												},
											]}
										>
											<FloatInput label="Complainee" placeholder="Complainee" />
										</Form.Item>
									</Col>
									<Col xs={24} sm={24} md={24} lg={12}>
										<Form.Item
											name="contact_no"
											rules={[
												{
													required: true,
													message: "This field is required",
												},
											]}
										>
											<FloatInputMask
												label="Contact Number"
												placeholder="Contact Number"
												maskLabel="contact_number"
												maskType="+63\ 999 999 9999"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} sm={24} md={24} lg={12}>
										<Form.Item
											name="email_address"
											rules={[
												{
													required: true,
													message: "This field is required",
													type: "email",
												},
											]}
										>
											<FloatInput label="Email" placeholder="Email" />
										</Form.Item>
									</Col>
									<Col xs={24} sm={24} md={24} lg={12}>
										<Form.Item
											name="barangay_id"
											rules={[
												{
													required: true,
													message: "This field is required",
												},
											]}
										>
											<FloatSelect
												label="Barangay"
												placeholder="Barangay"
												options={barangays}
											/>
										</Form.Item>
									</Col>
									<Col xs={24} sm={24} md={24} lg={24}>
										<Form.Item
											name="description"
											rules={[
												{
													required: true,
													message: "This field is required",
												},
											]}
										>
											<ReactQuill
												theme="snow"
												style={{ height: 200 }}
												formats={formats}
												modules={modulesToolBarV2}
												placeholder="Description"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} sm={24} md={24} lg={24}>
										<Form.Item name="address">
											<FloatTextArea
												label="Complete Address"
												placeholder="Complete Address"
											/>
										</Form.Item>
									</Col>
								</Row>
							</Collapse.Panel>
						</Collapse>
					</Col>
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Button
							htmlType="submit"
							className="btn-main-invert"
							loading={isLoadingCreateComplaint}
							size="large"
						>
							SUBMIT
						</Button>
					</Col>
				</Row>
			</Form>
		</Card>
	);
}
