import { faHome, faFilePen } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AdminSideMenu = [
	{
		title: "Dashboard",
		path: "/dashboard",
		icon: <FontAwesomeIcon icon={faHome} />,
	},
	{
		title: "Complaint",
		path: "/complaint",
		icon: <FontAwesomeIcon icon={faFilePen} />,
	},
];

export default AdminSideMenu;
