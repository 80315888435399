import { Card, Col, Collapse, Row, Table } from "antd";
import { useState } from "react";
import { userData } from "../../../providers/companyInfo";
import { GET } from "../../../providers/useAxiosQuery";

export default function PageDashboard() {
	const [tableFilter] = useState({
		user_id: userData().id,
		page_from: "UserDashboard",
	});

	const {
		data: dataSource,
		isLoading: isLoadingTable,
		isFetching: isFetchingTable,
	} = GET(`api/complain?${new URLSearchParams(tableFilter)}`, "complaint");

	return (
		<Card>
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24} lg={14}>
					<Collapse
						className="main-1-collapse border-none"
						expandIcon={({ isActive }) =>
							isActive ? (
								<span
									className="ant-menu-submenu-arrow"
									style={{ color: "#FFF", transform: "rotate(270deg)" }}
								></span>
							) : (
								<span
									className="ant-menu-submenu-arrow"
									style={{ color: "#FFF", transform: "rotate(90deg)" }}
								></span>
							)
						}
						defaultActiveKey={["1"]}
						expandIconPosition="start"
					>
						<Collapse.Panel
							header="UPLOADED COMPLAINT"
							key="1"
							className="accordion bg-darkgray-form m-b-md border bgcolor-1 white"
						>
							<Table
								className="ant-table-default ant-table-striped"
								dataSource={dataSource && dataSource.data}
								rowKey={(record) => record.id}
								loading={isLoadingTable || isFetchingTable}
								pagination={false}
								bordered={false}
								// rowSelection={{
								//   type: selectionType,
								//   ...rowSelection,
								// }}
								scroll={{ x: "max-content" }}
							>
								<Table.Column
									title="Ticket Number"
									key="ticket_number"
									dataIndex="ticket_number"
									// render={(text, record) => moment(text).format("MMMM DD, YYYY")}
								/>
								<Table.Column
									title="Complainant Name"
									key="complainant_name"
									dataIndex="complainant_name"
									// render={(text, record) => moment(text).format("MMMM DD, YYYY")}
								/>
								<Table.Column
									title="Complainee"
									key="complaine"
									dataIndex="complaine"
									// render={(text, record) => moment(text).format("MMMM DD, YYYY")}
								/>
								<Table.Column
									title="Description"
									key="description"
									dataIndex="description"
									// render={(text, record) => moment(text).format("MMMM DD, YYYY")}
								/>
								<Table.Column
									title="Contact Number"
									key="contact_no"
									dataIndex="contact_no"
									// render={(text, record) => moment(text).format("MMMM DD, YYYY")}
								/>
								<Table.Column
									title="Email Address"
									key="email_address"
									dataIndex="email_address"
									// render={(text, record) => moment(text).format("MMMM DD, YYYY")}
									sorter={true}
								/>
								<Table.Column
									title="Barangay"
									key="barangay_name"
									dataIndex="barangay_name"
									// render={(text, record) => moment(text).format("MMMM DD, YYYY")}
								/>
								<Table.Column
									title="Status"
									key="status"
									dataIndex="status"
									// render={(text, record) => moment(text).format("MMMM DD, YYYY")}
								/>
								<Table.Column
									title="Remarks"
									key="remarks"
									dataIndex="remarks"
									// render={(text, record) => moment(text).format("MMMM DD, YYYY")}
								/>
							</Table>
						</Collapse.Panel>
					</Collapse>
				</Col>
			</Row>
		</Card>
	);
}
