import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { faHome, faFilePen, faUpload } from "@fortawesome/pro-solid-svg-icons";

import "antd/dist/antd.css";

/** sass */
/** ui */
import "../assets/css/ui/helper/helper.css";
import "../assets/css/ui/card/card.css";
import "../assets/css/ui/input/input.css";
import "../assets/css/ui/button/button.css";
import "../assets/css/ui/tooltip/tooltip.css";
import "../assets/css/ui/checkbox/checkbox.css";
import "../assets/css/ui/datepicker/datepicker.css";
import "../assets/css/ui/steps/steps.css";
import "../assets/css/ui/radio/radio.css";
import "../assets/css/ui/quill/quill.css";
import "../assets/css/ui/typography/typography.css";
import "../assets/css/ui/spinner/spinner.css";
import "../assets/css/ui/collapse/collapse.css";
import "../assets/css/ui/accordion/accordion.css";
import "../assets/css/ui/navigation/navigation.css";
import "../assets/css/ui/pagination/pagination.css";
import "../assets/css/ui/tabs/tabs.css";
import "../assets/css/ui/modal/modal.css";
import "../assets/css/ui/table/table.css";
import "../assets/css/ui/header/header.css";
import "../assets/css/ui/sidemenu/sidemenu.css";
import "../assets/css/ui/breadcrumb/breadcrumb.css";
import "../assets/css/ui/page_header/page_header.css";
import "../assets/css/ui/upload/upload.css";
import "../assets/css/ui/form/form.css";
import "../assets/css/ui/footer/footer.css";
import "../assets/css/ui/public-layout/public-layout.css";
import "../assets/css/ui/private-layout/private-layout.css";

import "../assets/css/main/main.css";

/** end ui */

import "../assets/css/errors/maintenance/maintenance.css";

/** pages */

import "../assets/css/pages/login/login.css";
import "../assets/css/pages/create-password/create-password.css";
import "../assets/css/pages/register-layout/register-layout.css";
import "../assets/css/pages/dashboard/dashboard.css";
import "../assets/css/pages/profile/profile.css";
import "../assets/css/pages/payment-and-invoices/payment-and-invoices.css";
import "../assets/css/pages/messages/messages.css";
import "../assets/css/pages/profile-subscription/profile-subscription.css";

/** end pages */

/** end sass */

/** errors */

import Error404 from "../views/errors/Error404";
import Error500 from "../views/errors/Error500";
// import PageMaintenance from "../views/errors/PageMaintenance";

/** end errors */

/** public views */

import PageLogin from "../views/public/PageLogin/PageLogin";

import PageCreatePassword from "../views/public/PageCreatePassword/PageCreatePassword";
import PageRegister from "../views/public/PageRegister/PageRegister";
import PageRegistrationSetPassword from "../views/public/PageRegister/PageRegistrationSetPassword";
import PageForgotPassword from "../views/public/ForgotPassword/PageForgotPassword";

/** end public views */

/** private views */

import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import PageDashboard from "../views/private/PageDashboard/PageDashboard";
import PageComplaint from "../views/private/PageComplaint/PageComplaint";
import PageComplaintForm from "../views/private/PageComplaint/PageComplaintForm";
import PageComplaintPendingUpload from "../views/private/PageComplaint/PageComplaintPendingUpload";

/** end private views */

// const token = localStorage.token;
const queryClient = new QueryClient();

// console.log("userData", userData());

export default function Routes() {
	return (
		<QueryClientProvider client={queryClient}>
			<Router>
				<Switch>
					{/* public route */}
					<PublicRoute exact path="/" component={PageLogin} title="Login" />
					<PublicRoute
						exact
						path="/create-password"
						component={PageCreatePassword}
						title="Create Password"
					/>
					<PublicRoute
						exact
						path="/register"
						component={PageRegister}
						title="Register"
					/>
					<PublicRoute
						exact
						path="/register/:token"
						component={PageRegister}
						title="Register"
					/>
					<PublicRoute
						exact
						path="/forgot-password/:token"
						component={PageForgotPassword}
						title="Forgot Password"
					/>
					<PublicRoute
						exact
						path="/register/setup-password/:token"
						component={PageRegistrationSetPassword}
						title="Register - Setup Password"
					/>
					{/* end public route */}
					{/* private route */}
					<PrivateRoute
						exact
						path="/dashboard"
						title="Dashboard"
						subtitle="USER"
						component={PageDashboard}
						pageHeaderIcon={faHome}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
						]}
					/>
					<PrivateRoute
						exact
						path="/complaint"
						title="Complaint"
						subtitle="LIST"
						component={PageComplaint}
						pageHeaderIcon={faFilePen}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Complaint",
								link: "/complaint",
							},
						]}
					/>
					<PrivateRoute
						exact
						path="/complaint/add"
						title="Complaint"
						subtitle="ADD"
						component={PageComplaintForm}
						pageHeaderIcon={faFilePen}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Complaint",
								link: "/complaint",
							},
							{
								name: "Complaint Add",
								link: "/complaint/add",
							},
						]}
					/>
					<PrivateRoute
						exact
						path="/complaint/edit"
						title="Complaint"
						subtitle="EDIT"
						component={PageComplaintForm}
						pageHeaderIcon={faFilePen}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Complaint",
								link: "/complaint",
							},
							{
								name: "Complaint Edit",
								link: "/complaint/edit",
							},
						]}
					/>
					<PrivateRoute
						exact
						path="/complaint/pending-upload"
						title="Complaint"
						subtitle="EDIT"
						component={PageComplaintPendingUpload}
						pageHeaderIcon={faUpload}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Complaint",
								link: "/complaint",
							},
							{
								name: "Complaint Pending Upload",
								link: "/complaint/pending-upload",
							},
						]}
					/>
					{/* end private route */}
					{/* this should always in the bottom */}
					<Route exact path="/*" component={Error404} />
					<Route exact path="/500" component={Error500} />
				</Switch>
			</Router>
		</QueryClientProvider>
	);
}
