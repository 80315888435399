import { faPencil, faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Collapse, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { userData } from "../../../providers/companyInfo";

import {
	TablePageSize,
	TableGlobalSearch,
	TableShowingEntries,
	TablePagination,
} from "../../../providers/CustomTableFilter";
import getStorage from "../../../providers/getStorage";
import { GET } from "../../../providers/useAxiosQuery";

export default function PageComplaint() {
	const history = useHistory();

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "",
		sort_order: "asc",
		user_id: userData().id,
	});

	const {
		data: dataSource,
		refetch: refetchTable,
		isLoading: isLoadingTable,
		isFetching: isFetchingTable,
	} = GET(`api/complain?${new URLSearchParams(tableFilter)}`, "complaint");

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter({
			...tableFilter,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
		});
	};

	const [dataSourcePending, setDataSourcePending] = useState({
		data: [],
		barangay: [],
	});
	useEffect(() => {
		refetchTable();
		getStorage("complaintData").then((res) => {
			if (res) {
				// console.log("JSON.parse(res)", JSON.parse(res));
				let complaintData = JSON.parse(res).map((item, index) => {
					return {
						...item,
						key: index,
					};
				});
				console.log("complaintData", complaintData);
				getStorage("barangays").then((resBarangay) => {
					if (res) {
						// console.log('barangay',barangay);
						setDataSourcePending({
							data: complaintData,
							barangay: JSON.parse(resBarangay),
						});
					}
				});
			}
		});
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilter]);

	return (
		<Card>
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24}>
					<Button
						className="btn-main-invert-outline b-r-none"
						icon={<FontAwesomeIcon icon={faPlus} className="m-r-xs" />}
						onClick={(e) => {
							history.push("/complaint/add");
						}}
						size="large"
					>
						Create Complaint
					</Button>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					{dataSourcePending && dataSourcePending.data.length > 0 ? (
						<Collapse
							className="main-1-collapse border-none"
							expandIcon={({ isActive }) =>
								isActive ? (
									<span
										className="ant-menu-submenu-arrow"
										style={{ color: "#FFF", transform: "rotate(270deg)" }}
									></span>
								) : (
									<span
										className="ant-menu-submenu-arrow"
										style={{ color: "#FFF", transform: "rotate(90deg)" }}
									></span>
								)
							}
							defaultActiveKey={["1"]}
							expandIconPosition="start"
						>
							<Collapse.Panel
								header="PENDING UPLOADS"
								key="1"
								className="accordion bg-darkgray-form m-b-md border bgcolor-1 white"
							>
								<Table
									className="ant-table-default ant-table-striped"
									dataSource={dataSourcePending && dataSourcePending.data}
									rowKey={(record) => record.key}
									pagination={false}
									bordered={false}
									onChange={onChangeTable}
									// rowSelection={{
									//   type: selectionType,
									//   ...rowSelection,
									// }}
									scroll={{ x: "max-content" }}
								>
									<Table.Column
										title="Complainant Name"
										key="complainant_name"
										dataIndex="complainant_name"
										defaultSortOrder={"ascend"}
										// render={(text, record) => moment(text).format("MMMM DD, YYYY")}
										sorter={true}
									/>
									<Table.Column
										title="Complaine"
										key="complaine"
										dataIndex="complaine"
										defaultSortOrder={"ascend"}
										// render={(text, record) => moment(text).format("MMMM DD, YYYY")}
										sorter={true}
									/>
									<Table.Column
										title="Description"
										key="description"
										dataIndex="description"
										defaultSortOrder={"ascend"}
										// render={(text, record) => moment(text).format("MMMM DD, YYYY")}
										sorter={true}
									/>
									<Table.Column
										title="Contact Number"
										key="contact_no"
										dataIndex="contact_no"
										defaultSortOrder={"ascend"}
										// render={(text, record) => moment(text).format("MMMM DD, YYYY")}
										sorter={true}
									/>
									<Table.Column
										title="Email Address"
										key="email_address"
										dataIndex="email_address"
										defaultSortOrder={"ascend"}
										// render={(text, record) => moment(text).format("MMMM DD, YYYY")}
										sorter={true}
									/>
									<Table.Column
										title="Barangay"
										key="barangay_id"
										dataIndex="barangay_id"
										defaultSortOrder={"ascend"}
										render={(text, _) => {
											let bar = "";
											if (text) {
												let barangayFiltered =
													dataSourcePending.barangay.filter(
														(barangayFilter) => barangayFilter.value === text
													);
												if (barangayFiltered.length > 0) {
													bar = barangayFiltered[0].label;
												}
											}

											return bar;
										}}
										sorter={true}
									/>
									<Table.Column
										title="Status"
										key="status"
										dataIndex="status"
										align="center"
										// defaultSortOrder={"ascend"}
										render={(text, _) => "Pending Upload"}
										sorter={true}
									/>
									<Table.Column
										title="Action"
										key="action"
										align="center"
										render={(_, record) => {
											console.log("record", record);
											return (
												<Button
													type="link"
													className="color-1"
													onClick={() => {
														history.push({
															pathname: "/complaint/pending-upload",
															state: `${record.key}`,
														});
													}}
													icon={<FontAwesomeIcon icon={faUpload} />}
												/>
											);
										}}
									/>
								</Table>
							</Collapse.Panel>
						</Collapse>
					) : null}
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Collapse
						className="main-1-collapse border-none"
						expandIcon={({ isActive }) =>
							isActive ? (
								<span
									className="ant-menu-submenu-arrow"
									style={{ color: "#FFF", transform: "rotate(270deg)" }}
								></span>
							) : (
								<span
									className="ant-menu-submenu-arrow"
									style={{ color: "#FFF", transform: "rotate(90deg)" }}
								></span>
							)
						}
						defaultActiveKey={["1"]}
						expandIconPosition="start"
					>
						<Collapse.Panel
							header="UPLOADED COMPLAINT"
							key="1"
							className="accordion bg-darkgray-form m-b-md border bgcolor-1 white"
						>
							<Row gutter={[12, 12]}>
								<Col xs={24} sm={24} md={24}>
									<div className="ant-space-flex-space-between table-size-table-search">
										<div>
											<TablePageSize
												tableFilter={tableFilter}
												setTableFilter={setTableFilter}
											/>
										</div>

										<div>
											<TableGlobalSearch
												tableFilter={tableFilter}
												setTableFilter={setTableFilter}
											/>
										</div>
									</div>
								</Col>
								<Col xs={24} sm={24} md={24}>
									<Table
										className="ant-table-default ant-table-striped"
										dataSource={dataSource && dataSource.data.data}
										rowKey={(record) => record.id}
										loading={isLoadingTable || isFetchingTable}
										pagination={false}
										bordered={false}
										onChange={onChangeTable}
										// rowSelection={{
										//   type: selectionType,
										//   ...rowSelection,
										// }}
										scroll={{ x: "max-content" }}
									>
										<Table.Column
											title="Ticket Number"
											key="ticket_number"
											dataIndex="ticket_number"
											defaultSortOrder={"ascend"}
											// render={(text, record) => moment(text).format("MMMM DD, YYYY")}
											sorter={true}
										/>
										<Table.Column
											title="Complainant Name"
											key="complainant_name"
											dataIndex="complainant_name"
											// render={(text, record) => moment(text).format("MMMM DD, YYYY")}
											sorter={true}
										/>
										<Table.Column
											title="Complainee"
											key="complaine"
											dataIndex="complaine"
											// render={(text, record) => moment(text).format("MMMM DD, YYYY")}
											sorter={true}
										/>
										<Table.Column
											title="Description"
											key="description"
											dataIndex="description"
											// render={(text, record) => moment(text).format("MMMM DD, YYYY")}
											sorter={true}
										/>
										<Table.Column
											title="Contact Number"
											key="contact_no"
											dataIndex="contact_no"
											// render={(text, record) => moment(text).format("MMMM DD, YYYY")}
											sorter={true}
										/>
										<Table.Column
											title="Email Address"
											key="email_address"
											dataIndex="email_address"
											// render={(text, record) => moment(text).format("MMMM DD, YYYY")}
											sorter={true}
										/>
										<Table.Column
											title="Barangay"
											key="barangay_name"
											dataIndex="barangay_name"
											// render={(text, record) => moment(text).format("MMMM DD, YYYY")}
											sorter={true}
										/>
										<Table.Column
											title="Status"
											key="status"
											dataIndex="status"
											// render={(text, record) => moment(text).format("MMMM DD, YYYY")}
											sorter={true}
										/>
										<Table.Column
											title="Remarks"
											key="remarks"
											dataIndex="remarks"
											// render={(text, record) => moment(text).format("MMMM DD, YYYY")}
											sorter={true}
										/>
										<Table.Column
											title="Action"
											key="action"
											align="center"
											render={(_, record) => {
												let btn_edit = "";
												if (record.status === "Pending") {
													btn_edit = (
														<Button
															type="link"
															className="color-1"
															onClick={() => {
																history.push({
																	pathname: "/complaint/edit",
																	state: record.id,
																});
															}}
															icon={<FontAwesomeIcon icon={faPencil} />}
														/>
													);
												}

												return btn_edit;
											}}
										/>
									</Table>
								</Col>
								<Col xs={24} sm={24} md={24}>
									<div className="ant-space-flex-space-between table-entries-table-pagination tbl-uploaded">
										<TableShowingEntries />

										<TablePagination
											tableFilter={tableFilter}
											setTableFilter={setTableFilter}
											setPaginationTotal={dataSource && dataSource.data.total}
											showLessItems={true}
											showSizeChanger={false}
											parentClass="tbl-uploaded"
										/>
									</div>
								</Col>
							</Row>
						</Collapse.Panel>
					</Collapse>
				</Col>
			</Row>
		</Card>
	);
}
